module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/buildhome/repo/src/components/Layout.tsx"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"717785298905756"},
    },{
      plugin: require('../node_modules/gatsby-plugin-reddit-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"t2_97fuzz31"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"o5c6m"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-204667109-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/terms-of-use","/privacy-policy"],"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"example.com","enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","nl"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://flowyour.money","i18nextOptions":{"ns":["header","footer","home","dragonsDen","download","modal","blog","privacyPolicy","pricing"],"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/blog/business/:uid","getLanguageFromPath":true,"excludeLanguages":["nl"]},{"matchPath":"/:lang?/blog/news/:uid","getLanguageFromPath":true,"excludeLanguages":["nl"]},{"matchPath":"/:lang?/blog/money-methods/:uid","getLanguageFromPath":true,"excludeLanguages":["nl"]},{"matchPath":"/:lang?/blog/stories/:uid","getLanguageFromPath":true,"excludeLanguages":["nl"]},{"matchPath":"/:lang?/privacy-policy","getLanguageFromPath":true},{"matchPath":"/:lang?/cookies","getLanguageFromPath":true},{"matchPath":"/:lang?/responsable-disclosure-policy","getLanguageFromPath":true},{"matchPath":"/:lang?/terms-of-use","getLanguageFromPath":true},{"matchPath":"/:lang?/flow-snelstart-partnership","getLanguageFromPath":true},{"matchPath":"/","languages":["en","nl"]},{"matchPath":"/webinar","getLanguageFromPath":false}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MVJ4XVZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"YOUR_ROUTE_CHANGE_EVENT_NAME","enableWebVitalsTracking":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
